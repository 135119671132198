import Posts from '../api/index'
import history from '../history'
Posts.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }
    return req
})
export const fetchPosts = () => async (dispatch) => {
    try {
        const response = await Posts.get('/posts')
        dispatch({ type: 'FETCH_POSTS', payload: response.data })
        dispatch({type: 'END_LOADING'})
    } catch (err) {
        console.log(err)
    }
}

export const createPosts = (post) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        const response = await Posts.post('/posts', post)
        dispatch({ type: 'CREATE_POST', payload: response.data })
        dispatch({type: 'END_LOADING'})
        history.push('/')
    }
    catch (err) {
        console.log(err)
     }

}

export const editPosts = (id, post) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        const response = await Posts.patch(`/posts/${id}`, post)
        dispatch({ type: 'EDIT_POST', payload: response.data })
        dispatch({type: 'END_LOADING'})
        history.push('/')
    } catch (err) {
        console.log(err)
    }
}

export const fetchPost = (id) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        const response = await Posts.get(`/posts/${id}`)
        dispatch({ type: 'FETCH_POST', payload: response.data })
        dispatch({type: 'END_LOADING'})
    } catch (err) {
        console.log(err)
    }
}

export const searchPost = (searchQuerys) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        const { data } = await Posts.get(`/posts/search?searchPost=${searchQuerys.posts || 'none'}&searchTag=${searchQuerys.tags || 'none'}`)
        dispatch({ type: 'FETCH_POSTS', payload: data.data })
        dispatch({type: 'END_LOADING'})
    } catch (err) {
        console.log(err)
    }
}

export const deletePost = (id) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        await Posts.delete(`/posts/${id}`)
        dispatch({ type: 'DELETE_POST', payload: id })
        dispatch({type: 'END_LOADING'})
    } catch (err) {
        console.log(err)
    }
}

export const likePost = (id) => async (dispatch) => {
    try {
        dispatch({type: 'START_LOADING'})
        const { data } = await Posts.patch(`/posts/${id}/likePost`)
        dispatch({ type: 'UPDATE_LIKES', payload: data })
        dispatch({type: 'END_LOADING'})
    } catch (err) {
        console.log(err)
    }
}

export const startLoading = () => {
    return {type: 'START_LOADING'
    }
}