import React from 'react'
import { connect } from 'react-redux'
import {fetchPosts, searchPost, startLoading} from '../../actions/index'
import Post from './post/post'

import Loading from '../Loading/loading' 
import './posts.css'


class Posts extends React.Component {
    state = {postSearch: '',tagSearch: [],error: false, allPost: false}
    componentDidMount() {
            this.props.fetchPosts()
    }

    handleOnSubmit = (e) => {
        e.preventDefault()
        if (!this.state.postSearch && !this.state.tagSearch) {
            this.setState({ error: true })
            setTimeout(() => {
                this.setState({error: false})
            },2000)
        } else {
            if (this.state.postSearch) {
                this.props.searchPost({posts:this.state.postSearch})
            }
            else if (this.state.tagSearch) {
                 this.props.searchPost({tags:this.state.tagSearch })
            } else {
                this.props.searchPost({posts:this.state.postSearch, tagSearch: this.state.tagSearch})
            }
            this.setState({allPost: true})
        }
        this.setState({tagSearch: ''})
        this.setState({postSearch: ''})
    }
  
    render() {
        const renderPost = this.props.posts.posts.map((post, i) => {
            return (
              <Post  post={post} key={i} />)
        })
        return (
            <div className="posts">
                {(this.props.posts.posts.length === 0 && !this.props.posts.isLoading)  && <h1 className="posts_nopost">No Posts Found!</h1>}
                {this.props.posts.isLoading && <div className="posts__loader"><Loading /></div>}
                <div className="posts__container">
                    {renderPost}
                </div>
                <div className="posts__search">
                    <div className="posts__search--container">
                        <form onSubmit={this.handleOnSubmit}>
                            <h3>Search Post By Title</h3>
                            {this.state.error && <p className="posts__search--error">type something!</p>}
                            <input onChange={(e) => {this.setState({postSearch: e.target.value})}} value={this.state.postSearch}/>
                            <br />
                            <h3>Search Post By Tags</h3>
                            {this.state.error && <p className="posts__search--error">type something!</p>}
                            <input onChange={(e) => { this.setState({ tagSearch: e.target.value }) }} value={this.state.tagSearch} />
                            <br/>
                            <button>Search</button>
                        </form>
                        <button  onClick={() => {
                            if (this.state.allPost) {
                                    this.props.startLoading()
                                    this.props.fetchPosts()
                                    this.setState({allPost:false})
                                }
                            }}>All Posts</button>
                    </div>
                </div>
            </div>
        )     
    }
}

const mapStateToProp = (state) => {
    return {posts: state.posts}
}

export default connect(mapStateToProp, {fetchPosts, searchPost, startLoading})(Posts)