import React from 'react'
import './loading.css'

const Loading = (props) => {
    return (
        <div className="container">
            <div className="spinner">
                <div></div>
                <div></div>
          </div>
        </div>
    )

}

export default Loading