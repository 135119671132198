import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { fetchPosts } from '../../actions/index'
import { deleteComment, getComment } from '../../actions/comment'

import history from '../../history'
import Loading from '../Loading/loading'
import Comment from './comment/comment'
import './postDetail.css'

const PostDetail = (props) => {
    const postLoading = useSelector((state) => state.posts.isLoading)
    const post = useSelector((state) => state.posts.posts.filter((post) => post._id === props.match.params.id)[0])
    const comments = useSelector((state) => state.comments)
    const user = JSON.parse(localStorage.getItem('profile'))
    const dispatch = useDispatch()

    const onDelete = (comment_id) => {
        dispatch(deleteComment(comment_id, props.match.params.id))
        setTimeout(() => {
            dispatch(getComment( props.match.params.id))
        },400)
    }
    useEffect(() => {
        dispatch(fetchPosts())
        dispatch(getComment(props.match.params.id))
    }, [])

    if (post && !postLoading) {
        return (
            <>
                <div className="postdetail">
                    <div className="postdetail__container">
                        <div className="postdetail__details">
                            <h1>{post.title}</h1>
                            <p>{post.tags.map((tag) => `#${tag}`)}</p>
                            <h3>{post.message}</h3>
                            <h5>Created By: {!post.name? 'unknown':post.name}</h5>
                            <h5>Posted: {moment(post.createdAt).fromNow()}</h5>
                            <h5>created on: {moment(post.createdAt).format('L')}</h5>
                            <h5>Total Likes: {post.likes.length} <i class="fas fa-heart"></i></h5>
                            <button onClick={() => history.push('/')}>Back</button>
                        </div>
                        <div className="postdetail__image">
                            <img alt="postic" src={post.selectedFile}/>
                        </div>
                        <div className="postdetail__comment--post">
                            <Comment id={props.match.params.id}/>
                        </div>
                        <div className="postdetail__comment">
                            <h2>Comments</h2>
                            {comments?.map((comment) => {
                                return (
                                    <div className="postdetail__comment--card" key={comment?._id}>
                                        <div className="postdetail__profile-pic"><i className="fas fa-user"></i></div>
                                        <h3>{comment?.user?.name}</h3>
                                        <h4>{comment?.body}</h4>
                                        {user?.result?._id === comment?.user?._id && <button onClick={() => onDelete(comment?._id)}>Delete</button>} 
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
          </>
      )   
    }
    else {
        return  <div className="createpost__loader">  <Loading /></div>
    }

}

export default PostDetail