import Posts from '../api/index'

export const getComment = (id) => async (dispatch) => {
    const { data } = await Posts.get(`/comments/${id}`)
    dispatch({ type: 'FETCH_COMMENTS', payload: data })
}

export const createComment = (id,comment) => async (dispatch) => {
    const { data } = await Posts.post(`/comments/${id}`, comment)
    dispatch({ type: 'CREATE_COMMENT', payload: data })
}

export const deleteComment = (commentId, id) => async (dispatch) => {
    await Posts.delete(`/comments/${commentId}/posts/${id}`)
    dispatch({ type: 'DELETE_COMMENT', payload: commentId })
}