import React from 'react'
import './main.css'
import Posts from '../Post/posts'

const Main = () => {
    return (
        <>
        <Posts/>
    </>
    )
}

export default Main