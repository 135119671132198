import React from 'react'
import { connect } from 'react-redux'
import { editPosts, fetchPost } from '../../actions/index'
import _ from 'lodash'
import Loading from '../Loading/loading'

import Form from '../Form/form'
import './editpost.css'


 
class EditPost extends React.Component{
    componentDidMount() {
        this.props.fetchPost(this.props.match.params.id)
    }
    onSubmit = (value, selectedFile) => {
        this.props.editPosts(this.props.match.params.id, {...value, selectedFile: selectedFile})
    }
    render() {
        const id = this.props.match.params.id
        return( <div className="editpost">
                    <div className="editpost__container">
                        <h1>Edit Post</h1>
                        <Form onSubmit={this.onSubmit} initialValues={_.pick(this.props.post.posts.filter((post) => post._id === id)[0], ['message', 'title', 'tags'])} selectedFile={this.props.post?.posts.filter((post) => post._id === id)[0].selectedFile} />
                        {this.props.post.isLoading && <div className="createpost__loader">  <Loading /></div>}
                    </div>
                </div>  )
    }
}

const mapStateToProp = (state, ownProps) => {
    return {
        post: state.posts
    }
}

export default connect(mapStateToProp, {editPosts,fetchPost})(EditPost)