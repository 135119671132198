import React from 'react'
import { connect } from 'react-redux'
import { createPosts } from '../../actions/index'

import Loading from '../Loading/loading'
import Form from '../Form/form'
import './createpost.css'

class Createpost extends React.Component {
    constructor() {
        super()
        this.user = JSON.parse(localStorage.getItem('profile'))
    }
    
    onSubmit = (value, selectedFile) => {
        this.props.createPosts({ ...value, name: this.user?.result?.name, selectedFile: selectedFile, tags: value.tags.split(" ") })
    }

    render() {
        return (
            <div className="createpost">
                <div className="createpost__container">
                    <h1>Create Post</h1>
                    <Form onSubmit={this.onSubmit} />
                    {this.props.post && <div className="createpost__loader">  <Loading /></div>}
                </div>
          </div> 
            
        )
    }
}

const mapStateToProp = (state) => {
    return {post: state.posts.isLoading}
}

export default connect(mapStateToProp, {createPosts})(Createpost)