import React, { useState } from 'react'
import { useDispatch} from 'react-redux'
import { createComment} from '../../../actions/comment'
import './comment.css'

const Comment = (props) => {
    const [comment, setComment] = useState({ body: '' })
    const [valid, setValid] = useState(true)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
 
    const handleSubmit = (e) => {
        e.preventDefault()
        if (comment.body.length !== 0 && user) {
            dispatch(createComment(props.id, comment))
            setComment({...comment,body: ''})
        } else {
            if (!user) {
                setError(true)
                setTimeout(() => {
                    setError(false)
                }, 3000)
            } else {
                setValid(false)
                setTimeout(() => {
                    setValid(true)
                    setError(false)
                }, 3000)
            }
        }
    }

    return (
        <div className="comment__container">
            <form className="comment__form" onSubmit={handleSubmit}>
                {!valid && <p>There is no comments</p>}
                {error && <p>Login to Comment</p>}
                <h2>Post Your Comments</h2>
                <textarea onChange={(e) => { setComment({...comment,body: e.target.value}) }} value={comment.body}  rows="4" cols="50" placeholder="Add Your Comments"></textarea>
                <button>Submit</button>
            </form>
        </div>
    )

}

export default Comment