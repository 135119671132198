import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import history from '../../../history'

import './post.css'
import {deletePost,likePost } from '../../../actions/index'

const Post = (props) => {
    const { post } = props
    const dispatch = useDispatch()
    const [liked, setLiked] = useState(false)
    const [likeError, setLikeError] = useState(false)
    const user = JSON.parse(localStorage.getItem('profile'))

    useEffect(() => {
       const like =  post.likes.filter((like) => 
          like === String(user?.result?._id)
        )
        if (like[0]) {
            setLiked(true)
        } else {
            setLiked(false)
        }
    },[post.likes])
    if (props.post) {
        return (
            <div className="post">
                <div className="post__container">
                    {likeError && <p className="post__error--login">Login to Like!</p>}
                    <div className="posts__click" onClick={() => history.push(`/post/${post._id}`)}>
                        <div className="post__img">
                            <img src={!post.selectedFile ? 'https://media.musclegrid.io/agelesskarate.com/uploads/2017/04/26045848/default-image.jpg' : post.selectedFile} alt={post._id}/>
                            <h2>{post.name}</h2>
                            <p>{moment(post.createdAt).fromNow()}</p>
                        </div>
                        <div className="post__content">
                            <p>{post.tags.map((tag) => {
                                return `#${tag}`
                            })}</p>
                            <h2>{post.title}</h2>
                            <h4>{post.message.slice(0, 25)}{post.message.length > 25 && '....'}</h4>
                        </div>
                    </div>
                    <div className="post__buttons">
                        <button onClick={() => {
                            if (user) {
                                dispatch(likePost(post._id))
                            } else {
                                setLikeError(true)
                                setTimeout(() => {
                                    setLikeError(false)
                                },3000)
                            }
                        }}>{!liked ? <i class="far fa-heart"></i> : <i class="fas fa-heart"></i>} {post.likes.length}</button>
                        {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) &&(
                            <button onClick={() => { dispatch(deletePost(post._id)) }} disabled={!user?.result}><i class="fas fa-trash"></i>Delete</button>)}
                        {(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) &&(
                        <button onClick={() => history.push(`/edit/${post._id}`)}>Edit...</button>)}
                    </div>
                </div>
            </div>    
) 
     } else {
        return <h1>...loading</h1>
     }
   
}

export default Post