const reducer = (state = {isLoading: true, posts: []}, action) => {
    switch (action.type) {
        case "START_LOADING":
            return { ...state, isLoading: true }
            case "END_LOADING":
                return { ...state,isLoading: false }
        case 'FETCH_POSTS':
            return {...state, posts:action.payload }
        case 'CREATE_POST':
            return {...state, posts:[...state.posts, action.payload] }
        case 'EDIT_POST':
            return {...state, posts:[...state.posts, action.payload] }
        case 'FETCH_POST':
            return {...state, posts: [...state.posts,action.payload] }
        case 'DELETE_POST':
            return { ...state, posts: state.posts.filter((post) => post._id !== action.payload) }
        case 'UPDATE_LIKES':
            return { ...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post) }
        default:
            return state
    }
}

export default reducer