const authReducers = (state={authData: null, isLoadingAuth:null}, action) => {
    switch (action.type) {
        case "START_LOADING_AUTH":
            return { ...state, isLoadingAuth: true }
            case "END_LOADING_AUTH":
                return {...state, isLoadingAuth: false}
        case 'AUTH':
            localStorage.setItem('profile', JSON.stringify({...action?.data}))
            return { ...state, authData: action?.data }
        case 'LOGOUT':
            localStorage.clear()
        default:
            return state
    }

}

export default authReducers